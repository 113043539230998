/**
 * @module
 */
import Searcher from "../Searcher.js"
import ResultType from "../../ResultType.js"
/**
 * person_soeg_virksomhedsfokus til Redata
 * @extends module:js/searchers/Searcher
 * @example <caption>YAML Declaration:</caption>
 *   _type: Septima.Search.Redata.PersonSearcher
 *   _options:
 *     fetcher:
 *       _type: Septima.Search.Redata.Fetcher
 *       _options:
 *         token: tttttttttttttt
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 var fetcher = new Septima.Search.Redata.Fetcher({token: "......"})
 var personSearcher = new Septima.Search.Redata.PersonSearcher({
    fetcher: fetcher
  });
 controller.addSearcher(personSearcher);
 * @api
 **/
export default class PersonSearcher extends Searcher {
  /**
   * @param {Object} options
   * @param {Object} options.fetcher Septima.Search.Redata.Fetcher instance
   **/
  constructor(options = {}) {
    super(Object.assign({}, options, {usesGeoFunctions: true}))
    this.fetcher = options.fetcher

    this.source = "databox"
    this.type =  new ResultType({id: "person", singular: "Person", plural: "Personer", queryBehaviour: "search", geometrySupport: "hasGeometry"})
    
    this.registerType(this.source, this.type)
    
  }
  async asyncFetchData(query) {
    let results = this.createQueryResult()
    let items = await this.fetcher.search("person_soeg_virksomhedsfokus", query.queryString, Math.max(query.limit, 100) + 1)
    let count = items.length
    if (query.type === 'collapse') {
      let title = this.type.plural
      if (count > 0) {
        if (!query.isBlank) {
          if (count > Math.max(query.limit, 100))
            title += ` (${Math.max(query.limit, 100)}+)`
          else
            title += ` (${count})`
        }
        results.addNewQuery(this.source, this.type.id, title, null, query.queryString, null, null)
      }
    }else {
      let hitsShown = (count === 1) ? 1 : (query.type === 'no-cut' && count > query.limit) ? 0 : Math.min(count, query.limit)
      for (let item of items.slice(0, hitsShown))
        this.addItemAsResult(results, item)
      if ( count > hitsShown && ["no-cut", "cut"].indexOf(query.type) !== -1 ) {
        let title = this.type.plural
        if (!query.isBlank) {
          if (count > Math.max(query.limit, 100))
            title += ` (${Math.max(query.limit, 100)}+)`
          else
            title += ` (${count})`
        }
        results.addNewQuery(this.source, this.type.id, title, null, query.queryString, null, null)
      }
    }
    return results
  }

  addItemAsResult(results, item) {
    let resultGeometry = null
    if (item.geometri) {
      resultGeometry = item.geometri
      resultGeometry.crs = {
        "type": "name",
        "properties": {
          "name": "epsg:25832"
        }
      }
      let result = results.addResult(this.source, this.type.id, item.navn, item.bopael, resultGeometry, null)
      result.isComplete = false
      result.id = item.id
    }
  }
  
  async get(id) {
    let qr = this.createQueryResult()
    let items = await this.fetcher.get("person_search_virksomhedfokus", {id: "eq." + id})
    if (items.length == 1) {
      let item = items[0]
      let resultGeometry = item.geometri
      resultGeometry.crs = {
        "type": "name",
        "properties": {
          "name": "epsg:25832"
        }
      }
      let result = qr.addResult(this.source, this.type.id, item.navn, item.bopael, resultGeometry, item)
      result.id = id
      return result
    }
    return null
  }
}