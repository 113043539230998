import {VejAPI} from "./Vej.dawa.api.js"
import {AdgangsAdresseAPI} from "./AdgangsAdresse.dawa.api.js"
import {EnhedsAdresseAPI} from "./EnhedsAdresse.dawa.api.js"

const serviceUrl = "https://api.dataforsyningen.dk"

export class API {
  constructor(options = {}) {
    this.kommunekode  = "*"
    if (typeof options.kommunekode !== 'undefined') 
      this.kommunekode = options.kommunekode.toString()

    this.vejAPI = new VejAPI({kommunekode: this.kommunekode, serviceUrl})
    this.adgangsAdresseAPI = new AdgangsAdresseAPI({kommunekode: this.kommunekode, serviceUrl})
    this.enhedsAdresseAPI = new EnhedsAdresseAPI({kommunekode: this.kommunekode, serviceUrl})
  }

  /*
   * @param {Object} queryOptions 
   * @param {string} queryOptions.query
   * @param {string} queryOptions.limit
   * @param {string} queryOptions.goal
   * @param {string} queryOptions.showStreets
  */
  async query(queryOptions) {
    let hits = []
    //dawa må maks kaldes med 100 tegn - rigelig margen
    if (queryOptions.query.length < 80) {
      let goal = queryOptions.goal // "enhedsadresse" : "adgangsadresse"
      //Hvis der er indtastet en gyldig vejkode så returnér newQuery med vejen
      if (this.queryCouldBeVejkode(queryOptions.query)) {
        let vejStykkeHits = await this.vejAPI.getVejStykker({kode: queryOptions.query})
        if (typeof vejStykkeHits!== 'undefined') {
          for (let vejStykkeHit of vejStykkeHits)
            hits.push({type: "vejnavnpostnummer", vejnavn: vejStykkeHit.properties.navn, postnummer: vejStykkeHit.properties.postnumre[0].nr, postdistrikt: vejStykkeHit.properties.postnumre[0].navn})
        }
      } else {
        let vejQuery = this.vejAPI.query(queryOptions)
        let adgangsAdresseQuery = this.adgangsAdresseAPI.query(queryOptions)
        if (goal === 'adgangsadresse') {
          hits = await this.getHitsFromQueries(vejQuery, adgangsAdresseQuery, null, goal, queryOptions.showStreets, queryOptions.limit)
        } else {
          let enhedsAdresseQuery = this.enhedsAdresseAPI.query(queryOptions)
          hits = await this.getHitsFromQueries(vejQuery, adgangsAdresseQuery, enhedsAdresseQuery, goal, queryOptions.showStreets, queryOptions.limit)
        }
      }
    }
    return hits
  }
  
  async get (id, type) {
    if (type === 'enhedsadresse')
      return await this.enhedsAdresseAPI.get(id)
    else if (type === 'adgangsadresse')
      return await this.adgangsAdresseAPI.get(id)
    else if (type === 'vej')
      return await this.vejAPI.get(id)
  }

  async gets (ids, type) {
    if (type === 'enhedsadresse')
      return await this.enhedsAdresseAPI.gets(ids)
    else if (type === 'adgangsadresse')
      return await this.adgangsAdresseAPI.gets(ids)
    else if (type === 'vejstykke')
      return await this.vejAPI.gets(ids)
  }
  
  async getEnhedsAdresserForAdresse(adresseId) {
    return await this.enhedsAdresseAPI.getEnhedsAdresserForAdresse(adresseId)
  }
  
  async getVejForNavnPostnummer(navn, postnummer) {
    return await this.vejAPI.getVejForNavnPostnummer(navn, postnummer)
  }
  
  queryCouldBeVejkode(query) {
    if (query.length === 4 ) {
      let parseResult = parseInt(query)
      if( !isNaN(parseResult)) 
        return true
    }
    return false
  }

  async getHitsFromQueries(vejQuery, adgangsAdresseQuery, enhedsAdresseQuery, goal, showStreets, limit) {
    //goal:  // "enhedsadresse" : "adgangsadresse"
    
    let hits = []

    let vejnavnPostnummerCount = await vejQuery.getVejnavnPostnummerCount()
    if (vejnavnPostnummerCount > 1) {
      //Ikke-unikt vejnavn
      let vejnavnCount = await vejQuery.getVejnavnCount()
      if (vejnavnPostnummerCount <= limit || vejnavnCount == 1 || vejnavnCount == 0)
        hits = await vejQuery.getVejnavnPostnummerHits(limit)
      else if (vejnavnCount > 0)
        hits = await vejQuery.getVejnavneHits(limit)
      return hits
    }
    
    if (vejnavnPostnummerCount === 1) {
      if (goal === "adgangsadresse") {
        if (showStreets)
          hits.push(await vejQuery.buildVejHit())
        let adgangsAdresseHits = await adgangsAdresseQuery.getAdgangsAdresseHits(limit)
        hits = hits.concat(adgangsAdresseHits)
      } else {
        let adgangsAdresseHits = await adgangsAdresseQuery.getAdgangsAdresseHitsMedEnhedsInfo(limit)
        let adgangsAdresseCount = await adgangsAdresseQuery.getCount()
        if (goal === "adgangellerenhedsadresse") {
          if (adgangsAdresseCount === 1) {
            //unikt husnummer
            let adgangsAdresseHit = adgangsAdresseHits[0]
            adgangsAdresseHit.type = "lonelyadgangsadresseinfo"
            hits.push(adgangsAdresseHit)
            hits = hits.concat(adgangsAdresseHit.enhedsAdresser)
          } else {
            hits = hits.concat(adgangsAdresseHits)
          }
        }
        if (goal === "enhedsadresse") {
          if (adgangsAdresseCount === 1) {
            //unikt husnummer
            hits = hits.concat(adgangsAdresseHits[0].enhedsAdresser)
          } else {
            for (let adgangsAdresseHit of adgangsAdresseHits) {
              if (adgangsAdresseHit.enhedsAdresser.length == 0) {
                if (adgangsAdresseHit.skyggeAdresse) {
                  hits.push(adgangsAdresseHit.skyggeAdresse)
                }
              } else {
                hits.push(adgangsAdresseHit)
              }
            }
          }
        }
      }
      return hits
    }

    let adgangsAdresseCount = await adgangsAdresseQuery.getCount()
    if (adgangsAdresseCount > 1) {
      if (goal === "adgangsadresse") {
        let adgangsAdresseHits = await adgangsAdresseQuery.getAdgangsAdresseHits(limit)
        if (showStreets) {
          let adgangsAdresseHit = adgangsAdresseHits[0]
          hits.push(await vejQuery.getVejHit(adgangsAdresseHit.kommunekode, adgangsAdresseHit.vejkode))
        }
        hits = hits.concat(adgangsAdresseHits)
      } else {
        let adgangsAdresseHits = await adgangsAdresseQuery.getAdgangsAdresseHitsMedEnhedsInfo(limit)
        if (goal === "adgangellerenhedsadresse") {
          hits = hits.concat(adgangsAdresseHits) // som forslag, hvis der er enheder på den enkelte / Som resultat, hvis der ikke er enheder
        }
        if (goal === "enhedsadresse") {
          for (let adgangsAdresseHit of adgangsAdresseHits) {
            if (adgangsAdresseHit.enhedsAdresser.length == 0) {
              if (adgangsAdresseHit.skyggeAdresse) {
                hits.push(adgangsAdresseHit.skyggeAdresse)
              }
            } else {
              hits.push(adgangsAdresseHit)
            }
          }
        }
      }
      return hits
    }
    
    if (adgangsAdresseCount === 1) {
      //unikt husnummer
      let adgangsAdresseHits = await adgangsAdresseQuery.getAdgangsAdresseHits(limit)
      if (goal === "adgangsadresse") {
        if (showStreets) {
          let adgangsAdresseHit = adgangsAdresseHits[0]
          hits.push(await vejQuery.getVejHit(adgangsAdresseHit.kommunekode, adgangsAdresseHit.vejkode))
        }
        hits.push(adgangsAdresseHits[0])
      } else {
        let adgangsAdresseHits = await adgangsAdresseQuery.getAdgangsAdresseHitsMedEnhedsInfo(limit)
        let adgangsAdresseHit = adgangsAdresseHits[0]
        if (goal === "adgangellerenhedsadresse") {
          adgangsAdresseHit.type = "lonelyadgangsadresseinfo"
          hits.push(adgangsAdresseHit)
          hits = hits.concat(adgangsAdresseHit.enhedsAdresser)
        }
        if (goal === "enhedsadresse") {
          hits = hits.concat(adgangsAdresseHit.enhedsAdresser)
        }
      }
    
      return hits
    }
    
    if (goal === "adgangellerenhedsadresse" || goal === "enhedsadresse" ) {
      let enhedsAdresseCount = await enhedsAdresseQuery.getCount()
      if(enhedsAdresseCount > 0) {
        let enhedsAdresseHits = await enhedsAdresseQuery.getEnhedsAdresseHits()
        hits = hits.concat(enhedsAdresseHits)
      }
      return hits
    }
    return hits
  }
  
  /*
  async buildSearchResult_org(vejQueryResult, adresseQueryResult, enhedsAdresseQueryResult, goal, limit) {
    let enhAdrCount = enhedsAdresseQueryResult.getCount()
    let adrCount = adresseQueryResult.getAdresseCount()
    const vejCount = vejQueryResult.getVejCount()
    const vejnavnCount = vejQueryResult.getVejnavnCount()

    let result = []

    if (adrCount === 0) {
      return result
    } else if (vejCount > 1) {
      if (vejCount <= limit || vejnavnCount == 1)
        result =  vejQueryResult.buildVejResult(limit)
      else if (vejnavnCount > 0)
        result =  vejQueryResult.buildVejnavnResult(limit)
    }else {
      result = adresseQueryResult.buildAdresseResult(limit)
      if (this.showStreets)
        //Vis altid vej(ene) (Sikrer, at man kan vælge vejen)
        if (vejCount === 1)
          result = vejQueryResult.buildVejResult(limit).concat(result)
      //else if (typeof this.options.kommunekode !== 'undefined' && this.options.kommunekode !== "*")
      //  result = adresseQueryResult.buildVejResult().concat(result)
    }
    return result
  }
  
   */
}


