/**
 * @module
 */

import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import icons from "../../resources/icons.js"
/**
 * DEPRECATED - Use Septima.Search.DataApi
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
 * _type: Septima.Search.CVR_enhedSearcher
 * _options:
 *   kommunekode: '101'
 * detailhandlers:
 *   - _type: Septima.Search.CvrLinkHandler
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * cvr_enhedSearcher.addDetailHandlerDef(new Septima.Search.CvrLinkHandler({more: false}))
 *
 * @example <caption>ES6:</caption>
 * import CvrLinkHandler from './searchers/detailhandlers/CvrLinkHandler.js'
 * dawaSearcher.addDetailHandlerDef(new CvrLinkHandler({more: false}))
 * @api
 **/
export default class CvrLinkHandler extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {boolean} [options.more=true]
   **/
  constructor(options= {}) {
    // eslint-disable-next-line no-console
    if (console && console.warn) {
      try{
        // eslint-disable-next-line no-console
        console.warn("CvrLinkHandler is deprecated - Use Septima.Search.DataApi.")
        // eslint-disable-next-line no-empty
      } catch(e) {}
    }

    let defaultOptions = {
      buttonText: "link",
      buttonImage: icons.searchers.cvr,
      targets: [{source: "cvr", typeId: "produktionsenhed"}]
    }
    super(Object.assign(defaultOptions, options))
    this.handlerFunction = this.myHandler
  }

  async myHandler(result) {
    var items = []
    let url = "http://datacvr.virk.dk/data/visenhed?enhedstype=produktionsenhed&id=" + result.id
    items.push(
      { type: 'link',
        icon: this.more ? this.buttonImage : icons.details.link,
        link: url,
        linkTitle: "Se CVR-info på virk.dk"})
    return items
  }
}