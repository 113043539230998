/**
 * @module
 */
import Searcher from './Searcher.js'
import Icons from "../resources/icons.js"
import ResultType from "../ResultType.js"
import * as reproject from "../util/reproject.js"

/**
 *
 * Searches gst geoservice for stednavne (http://www.kortforsyningen.dk/dokumentation/geonoeglergeosearch)
 * @extends module:js/searchers/Searcher
 * @example <caption>YAML Declaration:</caption>
      _type: Septima.Search.GeoStednavnSearch
      _options:
        authParams:
          login: xxxx
          password: xxxx
        kommunekode: '101 157'


 * @example <caption> JS options:</caption>
 * geoStednavnSearchOptions = {
 *     authParams: {
 *      login: 'xxxx',
 *      password: 'xxxxxx'
 *     }
 * };
 * 
 * @example <caption>js client:</caption>
 * <!-- Include septimaSearch -->
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * controller.addSearcher(new Septima.Search.GeoStednavnSearch(geoStednavnSearchOptions))
 *
 * @example <caption>ES6:</caption>
 * import GeoStednavnSearch from './searchers/GeoStednavnSearch.js'
 * controller.addSearcher(new GeoStednavnSearch(geoStednavnSearchOptions))
 * @api
 */

export default class GeoStednavnSearcher extends Searcher {
  /**
   * @param {Object} options GeoSearch expects these properties:
   * @param {String} [options.kommunekode=*] "*" Search all municipalities (Default)</br>Search specific municipalities eg. "101" or "101|256"
   * @param  {Object} [options.authParams] Either {ticket: 'ttttt'}, where t is a ticket issued by kortforsyningen or {login: 'lll', password: 'pppp'}
   *
   */
  constructor(options= {}) {

    super(Object.assign({
      usesGeoFunctions: true,
      defaultCrs: "25832",
      iconURI: Icons.searchers.geoSearch.result
    },
    options))

    this.options = options
    this.serviceUrl =  'https://api.dataforsyningen.dk'

    if (!options.source) 
      options.source = "Kortforsyningen"
    
    this.source = options.source
    
    this.resultType = new ResultType({
      id: "stednavne",
      singular: "Stednavn",
      plural: "Stednavne"
    })
    this.registerType(this.source,  this.resultType)
    this.area = null
    if (options.kommunekode && options.kommunekode !== "*") {
      options.kommunekode += ''
      let municipalities = options.kommunekode.split(' ')
      for (let i=0; i<municipalities.length; i++) 
        municipalities[i] = "muncode0" + municipalities[i]
      
      this.area = municipalities.join()
    }else if (options.area) {
      this.area = options.area
    }
    this.authParams = {
      token: '22e4c7f57b6172b780abf7a447ebe7bd'
    }
    this.visUOfficiel = false
    if (options.visUOfficiel) 
      this.visUOfficiel = options.visUOfficiel

    reproject.registerCrs("EPSG:25832", "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs")
  }

  async fetchData(query, caller) {
    caller.fetchSuccess(await this.query(query))
  }

  async query(query) {
    let results = this.createQueryResult()
    let items = await this.fetchItems(query, Math.max(query.limit, 100) + 1)
    let count = items.length
    
    if (query.type === 'collapse') {
      if (count > 0) {
        let title = this.resultType.plural
        if (!query.isBlank)
          if (count > Math.max(query.limit, 100))
            title += ` (${Math.max(query.limit, 100)}+)`
          else
            title += ` (${count})`
        results.addNewQuery(this.source, this.resultType.id, title, null, query.queryString, null, null)
      }
    }else {
      let hitsShown = (count === 1) ? 1 : (query.type === 'no-cut' && count > query.limit) ? 0 : Math.min(count, query.limit)
      for (let item of items.slice(0, hitsShown))
        this.addItemAsResult(results, item)
      if ( count > hitsShown && ["no-cut", "cut"].indexOf(query.type) !== -1 ) {
        let title = this.resultType.plural
        if (!query.isBlank)
          if (count > Math.max(query.limit, 100))
            title += ` (${Math.max(query.limit, 100)}+)`
          else
            title += ` (${count})`
        results.addNewQuery(this.source, this.resultType.id, title, null, query.queryString, null, null)
      }
    }
    return results
  }

  async fetchItems(query, limit) {
    const params = {}
    params.limit = limit
    params.resources = 'stednavne_v3'
    
    if (this.area) 
      params.area = this.area

    let items = []
    
    if (query.isBlank) {
      params.search = "a b c d e f g h i j k l m n o p q r s t u v x y z æ ø å 0 1 2 3 4 5 6 7 8 9"
      params.limit += 100
      let response
      try {
        response = await this.fetch(this.serviceUrl + '/Geosearch?service=GEO', {timeout: 1000, data: Object.assign(params, this.authParams)})
      }catch(e) {
        return [] 
      }
      if (response.data !== null) {
        items = response.data
        items.sort((item1, item2) => item1.skrivemaade.localeCompare(item2.skrivemaade))
        items = items.slice(0, limit)
      }
    }else {
      params.search = query.queryString
      let response
      try {
        response = await this.fetch(this.serviceUrl + '/Geosearch?service=GEO', {timeout: 1000, data: Object.assign(params, this.authParams)})
      }catch(e) {
        return []
      }
      if (response.data !== null)
        items = response.data
      if (query.queryString.indexOf(" ") > 0) {
        params.search = query.queryString.replace(/ /g, "")
        let response = await this.fetch(this.serviceUrl + '/Geosearch?service=GEO', {timeout: 1000, data: Object.assign(params, this.authParams)})
        if (response.data !== null) {
          let otherItems = response.data
          if (otherItems.length < 5) 
            items = otherItems.concat(items)
        }
      }
    }

    let filteredItems = this.removeDuplicates(items)
    return filteredItems
  }

  removeDuplicates(items) {
    //https://ilikekillnerds.com/2016/05/removing-duplicate-objects-array-property-name-javascript/
    return items.filter((item, pos, arr) => {
      return arr.map(mapObj => mapObj.id_lokalid).indexOf(item.id_lokalid) === pos
    })
  }

  addItemAsResult(results, item) {
    let navn = item.skrivemaade
    // Enrich name with alternatives
    if (item.navnestatus === 'officielt' || item.navnestatus === 'suAutoriseret') {
      if ( this.visUOfficiel && item.skrivemaader_uofficiel.length > 0 ) 
        navn += ' (' + item.skrivemaader_uofficiel + ')'
      
    } else{
      if (item.skrivemaade_officiel.length > 0) 
        navn += ' (' + item.skrivemaade_officiel + ')'
      
    }
    //Create a description
    let description = item.kvalifikation
    let resultGeometry = this.translateWktToGeoJsonObject(item.geometryWkt)
    let result = results.addResult(this.source, this.resultType.id, navn, description, resultGeometry, item)
    result.id = item.id_lokalid
  }
  
  async get (id) {
    let qr = this.createQueryResult()
    //https://dawa.aws.dk/steder/12337669-67fe-6b98-e053-d480220a5a3f?format=geojson
    let url = "https://dawa.aws.dk/steder/" + id
    let params = {
      format: "geojson",
      srid: "25832"
    }
    let hit = await this.fetch(url, {data: params})
    let result = qr.addResult(this.source, this.resultType.id, hit.properties.primærtnavn, null, hit.geometry, hit)
    result.id = id
    return result
  }
}