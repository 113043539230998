/**
 * @module
 */
import {  fetch2  } from "../../utils.js"

/**
 * Forbinder til septima data-api / PostGrest
 * @example <caption>YAML Declaration:</caption>
 *   _type: Septima.Search.DataApi.Fetcher
 *   _options:
 *     token: tttttttttttttt
 *     er_aktiv:
 *       - false
 *       - true
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 var fetcher = new Septima.Search.DataApi.Fetcher({token: "......"})
 * @api
 **/
export default class Fetcher {
  /**
   * @param {Object} options
   * @param {string} options.token token til data-api
   * @param {Array} options.er_aktiv  skal medtage aktive ell er inaktive eller begge
   * @param {string} [options.endpoint=https://data-api.septima.dk]
   **/
  constructor(options = {}) {
    let token
    if (options.token)
      token = options.token
    else
      throw new Error("DataApi.Fetcher needs a token")

    let endpoint = "https://data-api.septima.dk"
    if (options.endpoint)
      this.endpoint = options.endpoint

    if (options.er_aktiv)
      this.er_aktiv = options.er_aktiv
    else
      this.er_aktiv = [true]

    this.service = endpoint + "/" + token + "/postgrest"
  }

  async search(searchRp, searchText, maxRows, params  = {}, logger) {
    let finalQuery = (searchText.trim() == "") ? "a" : searchText
    if (params.geometry)
      finalQuery = ""

    let postData = {
      searchtext: finalQuery,
      max_rows_returned: maxRows,
      filter: {}
    }

    postData.filter['er_aktiv'] = this.er_aktiv

    if (params.kommunekoder)
      postData.filter['kommuner'] = params.kommunekoder
    if (params.hovedafdelingkode)
      postData.filter['branche_hovedafdelinger'] = params.hovedafdelingkode
    if (params.hovedgruppekode)
      postData.filter['branche_hovedgrupper'] = params.hovedgruppekode
    if (params.gruppekode)
      postData.filter['branche_grupper'] = params.gruppekode
    if (params.undergruppekode)
      postData.filter['branche_undergrupper'] = params.undergruppekode
    if (params.branchekode)
      postData.filter['branchekoder'] = params.branchekode
    if (params.geometry)
      postData.filter['geometri'] = params.geometry

    let url = `${this.service}/rpc/${searchRp}`
    /*
    let response = await fetch(url,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
 */
    let options = {
      method: "post",
      contentType:'application/json',
      data: JSON.stringify(postData)
    }
    let response = await fetch2(url, options, logger)
    return response
  }

  async get(target, params, logger) {
    let url = `${this.service}/${target}`
    return await fetch2(url, {  data: params  }, logger)
  }

}
