import {fetch2} from "../../utils.js"

export default class Fetcher {
  /**
   * @param {Object} options
   * @param {string} options.token token til PostGrest
   * @param {string} [options.endpoint=https://redata-postgrest.septima.dk]
   **/
  constructor(options = {}) {
    if (options.token)
      this.token = options.token
    else
      throw new Error("Redata.Fetcher needs a token")
    
    this.endpoint = "https://redata-postgrest.septima.dk"
    if (options.endpoint)
      this.endpoint = options.endpoint
  }

  async search(target, searchText, maxRows, params= {}) {
    let finalQuery = (searchText.trim() == "") ? "a" : searchText
    if (params.geometry)
      finalQuery = ""
      
    let data = Object.assign( {
      searchtext: finalQuery,
      max_rows_returned: maxRows
    },
    params)
    let url = `${this.endpoint}/rpc/${target}`
    return await fetch2(url, {Authorization: {Bearer: {token: this.token} }, data: data})
  }

  async get(target, params) {
    let url = `${this.endpoint}/${target}`
    return await fetch2(url, {Authorization: {Bearer: {token: this.token} }, data: params})
  }

}
