/**
 * @module
 */

import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import icons from "../../resources/icons.js"
import * as reproject from "../../util/reproject.js"
import {getWKTParser} from "../../util/getWKTParser.js"
/**
 * Viser link til relevante offentlige hjemmesider for enten en adresse eller en matrikel
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
 * _type: Septima.Search.DawaSearcher
 * _options:
 *   kommunekode: '101'
 *   allowDetails: true
 * detailhandlers:
 *   - _type: Septima.Search.OffentligeLinksForAdresseMatrikel
 *     _options:
 *        links: ["jfa","bbrmeddelelse","ejendomsinfo.dk","boligejer.dk","vurdering","bbkort","ois"]
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * dawaSearcher.addDetailHandlerDef(new Septima.Search.OffentligeLinksForAdresseMatrikel({more: true}))
 *
 * @example <caption>ES6:</caption>
 * import OffentligeLinksForAdresseMatrikel from './searchers/detailhandlers/OffentligeLinksForAdresseMatrikel.js'
 * dawaSearcher.addDetailHandlerDef(new OffentligeLinksForAdresseMatrikel({more: false}))
 * @api
 **/
export default class OffentligeLinksProvider extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.links=["jfa","bbrmeddelelse","bbrejendom","boligejer.dk","vurdering","bbkort","ois"]] Hvilke links skal vises.
   * Muligheder: "jfa","bbrmeddelelse","bbrejendom","boligejer.dk","vurdering","bbkort","ois", "geokoder", "retbbr", "skraafoto", "skraafoto_js", "sdfekort", "plankort"
   * @param {Object} [options.visEsrNummer=true]
   **/


  constructor(options= {}) {
    if (!options.buttonText)
      options.buttonText = "Offentlige links"

    super(options)
    this.isApplicableFunction = this.isApplicable
    this.handlerFunction = this.myHandler

    this.visEsrNummer = true
    if (typeof options.visEsrNummer != "undefined" && options.visEsrNummer == false)
      this.visEsrNummer = false
      
    this.links = ["jfa","bbrmeddelelse","bbrejendom","boligejer.dk","vurdering","bbkort","ois"]
    if (typeof options.links !== 'undefined') {
      if (options.links.length > 0 && options.links[0] === "*")
        this.links = ["jfa","bbrmeddelelse","bbrejendom","boligejer.dk","vurdering","bbkort","ois", "geokoder", "retbbr", "skraafoto_js", "sdfekort", "plankort"]
      else
        this.links = options.links
    }
  }

  isApplicable() {
    return false
  }

  async myHandler() {
    return []
  }

  createLinks(result, komnr, esrnr, pointGeom, elavskode, matrnr, bfenr) {
    let items = []
    for (var link of this.links) {
      let item = this.createItem(result, link, komnr, esrnr, pointGeom, elavskode, matrnr, bfenr)
      if (item)
        items.push(item)
    }
    return items
  }

  createItem(result, link, komnr, esrnr, pointGeom, elavskode, matrnr, bfenr) {

    if (link === 'bbrmeddelelse' && komnr && esrnr) {
      let buttonImage= icons.pdf
      return {type: 'link',
        name: "bbrmeddelelse",
        icon: this.more ? buttonImage : icons.details.link,
        link: `https://bbr.dk/pls/wwwdata/get_ois_pck.show_bbr_meddelelse_pdf?i_municipalitycode=${komnr}&i_realpropertyidentifier=${esrnr}`,
        linkTitle: "BBR-meddelelse"}
    } else if (link === 'bbkort' && komnr && esrnr) {
      return {type: 'link',
        name: "kort.bbr.dk",
        icon: icons.globe,
        link: `https://kort.bbr.dk/link/esr/${komnr}/${esrnr}`,
        linkTitle: 'BBRkort ' + (this.visEsrNummer ? `(ESR ${esrnr})` : "" )}
    } else if (link === 'bbrejendom' && bfenr) {
      return {type: 'link',
        name: "bbrejendom",
        icon: icons.exlink,
        link: `https://bbr.dk/se-bbr-oplysninger/0/1/0/bfe:${bfenr}`,
        linkTitle: 'Ejendommens BBR-oplysninger'}
    } else if (link==='boligejer.dk' && komnr && esrnr) {
      return {type: 'link',
        name: "boligejer.dk",
        icon: icons.exlink,
        link: `http://boligejer.dk/ejendomsdata?knr=${komnr}&enr=${esrnr}`,
        linkTitle: 'Boligejer.dk ' + (this.visEsrNummer ? `(ESR ${esrnr})` : "" )}
    } else if (link==='vurdering' && komnr && esrnr) {
      return {type: 'link',
        name: "vurdering.skat.dk",
        icon: icons.exlink,
        link: `http://www.vurdering.skat.dk/borger/ejendomsvurdering/Vis.do?KMNR=${komnr.substr(1, 3)}&sideNavn=vstarte&EJDNR=${esrnr}&POSTNR=&VEJKODE=&VEJNAVN`,
        linkTitle: 'Ejendomsvurdering på skat.dk ' + (this.visEsrNummer ? `(ESR ${esrnr})` : "" )}
    } else if (link==='jfa' && elavskode && matrnr) {
      let url = "http://jordrapport.miljoeportal.dk/?elav=" +elavskode + "&matrnr="+ matrnr
      let buttonImage= icons.pdf
      return { type: 'link',
        name: "jordrapport.miljoeportal.dk",
        icon: this.more ? buttonImage : icons.details.link,
        link: url,
        linkTitle: "Jordforureningsattest fra Miljøportalen"}
    } else if (link==='ois' && komnr && esrnr) {
      return { type: 'link',
        name: "ois.dk",
        icon: icons.exlink,
        link: "http://www.ois.dk/ui/linkpartner/linkpartner.aspx?CBKORT=TRUE&komnr=" + komnr + "&ejdnr=" + esrnr,
        linkTitle: "OIS.dk"}
    } else if (link === 'geokoder' && pointGeom) {
      return null
      /*
      let pointCoord =`${pointGeom.coordinates[0]},${pointGeom.coordinates[1]}`
      return  {type: 'link',
        name: "geokoderen.dk",
        icon: icons.bbrhouse,
        link: `https://www.geokoderen.dk/#centrum=${pointCoord}`,
        linkTitle: `Vis i Geokoderen`}
       */
    } else if (link === 'retbbr' && komnr && esrnr) {
      return {type: 'link',
        name: "ret.bbr.dk",
        icon: icons.bbrhouse,
        link: `https://ret2.bbr.dk/oversigt/0/7/0/${komnr}|${esrnr}`,
        linkTitle: 'RetBBR ' + (this.visEsrNummer ? `(ESR ${esrnr})` : "" )}
    } else if ((link === 'skraafoto' || link === 'skraafoto_js') && pointGeom) {
      let latLon = this.convert2582GeometryTowgs84LatLon(pointGeom)
      let endpoint = "https://skraafoto.kortforsyningen.dk/oblivisionjsoff"
      if (link === 'skraafoto_js')
        endpoint = "https://skraafoto.kortforsyningen.dk/oblivisionjs"
      let url = endpoint + "/index.aspx?project=Denmark&lon=" + latLon.lon + "&lat=" + latLon.lat
      return { type: 'link',
        name: "skraafoto.kortforsyningen.dk",
        icon: icons.exlink,
        link: url,
        linkTitle: "Skråfoto fra SDFE"}
    } else if(link === 'sdfekort' && result.geometry) {
      let wktParser = getWKTParser()
      let wkt  = wktParser.convert(result.geometry)
      let url
      if (pointGeom) {
        let pointWKT = wktParser.convert(pointGeom)
        let label = result.title + (result.description ? ("-" + result.description) : "")
        url = `http://sdfekort.dk/spatialmap?wkt=${wkt}&selectorgroups=admgraens+matrikelkort+adresser+dhm&layers=theme-dtk_skaermkort_daempet_daf+theme-daf_matrikelkort_roed+theme_forvaltning2_husnummer+userpoint&label=${label}&labelPos=${pointWKT}`
      } else {
        url = `http://sdfekort.dk/spatialmap?wkt=${wkt}&selectorgroups=admgraens+matrikelkort+adresser+dhm&layers=theme-dtk_skaermkort_daempet_daf+theme-daf_matrikelkort_roed+theme_forvaltning2_husnummer+userpoint`
      }
      return { type: 'link',
        name: "sdfekort.dk",
        icon: icons.exlink,
        link: url,
        linkTitle: "SDFE Kortviser"}
    }else if(link === 'plankort' && result.geometry) {
      let wktParser = getWKTParser()
      let wkt  = wktParser.convert(result.geometry)
      let url
      if (pointGeom) {
        let pointWKT = wktParser.convert(pointGeom)
        let label = result.title + (result.description ? ("-" + result.description) : "")
        
        url = `http://kort.plandata.dk/spatialmap?wkt=${wkt}&selectorgroups=admgraens&layers=theme-dtk_skaermkort_daempet_daf+theme-daf_matrikelkort_roed+userpoint&label=${label}&labelPos=${pointWKT}`
      } else {
        url = `http://kort.plandata.dk/spatialmap?wkt=${wkt}&selectorgroups=admgraens&layers=theme-dtk_skaermkort_daempet_daf+theme-daf_matrikelkort_roed+userpoint`
      }
      return { type: 'link',
        name: "kort.plandata.dk",
        icon: icons.exlink,
        link: url,
        linkTitle: "Kort.plandata.dk"}
    }
  }

  convert2582GeometryTowgs84LatLon(geometry) {
    let geoJsonWgs84 = reproject.reproject(geometry, "EPSG:25832", "EPSG:4326")
    return {lon: geoJsonWgs84.coordinates[0], lat: geoJsonWgs84.coordinates[1]}
  }

}
