/**
 * @module
 */

import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import icons from "../../resources/icons.js"
import DawaSearcher from "../DawaSearcher.js"
import DetailItemsList from "../../details/DetailItemsList.js"
/**
 * DEPRECATED - Use Septima.Search.DataApi
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration: <a href='../examples/details/'>Example</a></caption>
 * _type: Septima.Search.CVR_enhedSearcher
 * _options:
 *   kommunekode: '101'
 * detailhandlers:
 *   - _type: Septima.Search.CvrInfoProvider
 *     _options:
 *       fields: ["hovedbranche","navn","email","tlf","adresse","adresse_result","cvrlink"]
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * cvr_enhedSearcher.addDetailHandlerDef(new Septima.Search.CvrInfoProvider({more: false}))
 *
 * @example <caption>ES6:</caption>
 * import CvrLinkHandler from './searchers/detailhandlers/CvrInfoProvider.js'
 * dawaSearcher.addDetailHandlerDef(new CvrInfoProvider({more: false}))
 * @api
 **/
export default class CvrInfoProvider extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {String[]} [options.fields=["hovedbranche", "navn", "adresse_result", "email", "tlf", "cvrlink", "virksomhed_navn", "virksomhed_cvrlink", "virksomhed_enhedsliste"]] Muligheder: ["pnummer","hovedbranche", "navn", "adresse_result", "email", "tlf", "cvrlink", "virksomhed_navn", "virksomhed_cvrlink", "virksomhed_enhedsliste"]
   * @param {String[]} [options.virksomhed_enhedsliste_komnumre] Kommunenumre i hvilke produktionsenheder for cvr-nummer skal fremsøges. Mellemrums-separereret (157 101)
   **/
  constructor(options = {}) {
    // eslint-disable-next-line no-console
    if (console && console.warn) {
      try{
        // eslint-disable-next-line no-console
        console.warn("CvrInfoProvider is deprecated - Use Septima.Search.DataApi.")
        // eslint-disable-next-line no-empty
      } catch(e) {}
    }

    let defaultOptions = {
      buttonText: "CVR-info",
      buttonImage: icons.searchers.cvr,
      komnumre: null,
      isApplicable: (result) => {
        return ((result.source === "cvr" && result.typeId === "produktionsenhed") || (result.source === "cvr" && result.typeId === "virksomhed"))
      }
    }
    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler

    this.fields = ["hovedbranche", "navn", "adresse_result", "email", "tlf", "cvrlink", "virksomhed_navn", "virksomhed_cvrlink", "virksomhed_enhedsliste"]
    if (typeof options.fields !== 'undefined')
      this.fields = options.fields

    if (typeof options.virksomhed_enhedsliste_komnumre !== 'undefined')
      this.komnumre = "" + options.virksomhed_enhedsliste_komnumre

    this.dawaSearcher = new DawaSearcher({})
  }

  async myHandler(result) {
    let items = []
    let virksomhed
    let produktionsenhed
    
    if (result.typeId === "produktionsenhed") {
      let cvrNummer = result.data.cvrnummerrelation
      produktionsenhed = result
      virksomhed = await result.searcher.get(cvrNummer, "virksomhed")
      for (let field of this.fields) {
        let item = await this.createPItem(produktionsenhed, field, virksomhed)
        if (item)
          items.push(item)
      }
    } else if (result.typeId === "virksomhed") {
      virksomhed = result
      for (let field of this.fields) {
        let item = await this.createVItem(virksomhed, field)
        if (item)
          items.push(item)
      }
    }
    return items
  }
  
  // eslint-disable-next-line no-unused-vars
  async createVItem (virksomhed, field) {
    return null
  }
  async createPItem(produktionsenhed, field, virksomhed) {

    if (field === 'cvrlink') {
      return {
        type: 'link',
        icon: icons.bbrhouse,
        label: `${produktionsenhed.title} på virk.dk`,
        link: `http://datacvr.virk.dk/data/visenhed?enhedstype=produktionsenhed&id=${produktionsenhed.id}`,
        linkTitle: `Produktionsenheden (${produktionsenhed.id}) på virk.dk`
      }
    } else if (field === 'navn') {
      return {
        type: 'labelvalue',
        label: `Navn`,
        value: `${produktionsenhed.data.navn}`
      }
    } else if (field === 'hovedbranche' && produktionsenhed.data.hovedbranche) {
      return {
        type: 'labelvalue',
        label: `Hovedbranche`,
        value: `${produktionsenhed.data.hovedbranche}`
      }
    } else if (field === 'pnummer') {
      return {
        type: 'labelvalue',
        label: `P-nummer`,
        value: `${produktionsenhed.id}`
      }
    } else if (field === 'email') {
      if  (produktionsenhed.data.email == null) {
        return {
          type: 'labelvalue',
          label: 'Email',
          value: 'Ikke angivet'
        }
      } else {
        let email = produktionsenhed.data.email
        return {
          type: 'link',
          label: `Email`,
          link: 'mailto:' + email,
          linkTitle: email
        }
      }
    } else if (field === 'tlf') {
      if (produktionsenhed.data.tlf == null) {
        return {
          type: 'labelvalue',
          label: 'Telefon',
          value: 'Ikke angivet'
        }
      } else {
        let tlf = produktionsenhed.data.tlf
        return {
          type: 'link',
          label: `Telefon`,
          value: tlf,
          link: 'tel:' + tlf,
          linkTitle: tlf
        }
        
      }
    } else if (field === 'adresse_result') {
      if (produktionsenhed.data.husnummer) {
        let adresseResult = await this.dawaSearcher.get(produktionsenhed.data.husnummer, 'adresse')
        return {
          type: 'result',
          label: 'Adresse',
          result: adresseResult
        }
      }
    } else if (field === 'adresse') {
      if (typeof produktionsenhed.adgangsadressebetegnelse != 'undefined')
        return {
          type: 'labelvalue',
          label: `Adresse`,
          value: produktionsenhed.adgangsadressebetegnelse
        }
    } else if (field === 'virksomhed_navn' && virksomhed) {
      let virksomhedsNavn = virksomhed.title
      return {
        type: 'labelvalue',
        label: `Virksomhed`,
        value: virksomhedsNavn
      }
    } else if (field === 'virksomhed_cvrlink' && virksomhed) {
      let cvrNummer = virksomhed.id
      return {
        type: 'link',
        icon: icons.exlink,
        label: `${virksomhed.title} på virk.dk`,
        link: `http://datacvr.virk.dk/data/visenhed?enhedstype=virksomhed&id=${cvrNummer}`,
        linkTitle: `Virksomheden (${cvrNummer}) på virk.dk`
      }

    } else if (field === 'virksomhed_enhedsliste' && virksomhed) {
      let cvrNummer = virksomhed.id
      let queryResult = await virksomhed.searcher.getProduktionsEnhederForCvrNummer(cvrNummer, this.virksomhed_enhedsliste_komnumre)
      let pResults = queryResult.getResults()
      if (pResults.length > 0) {
        let detailItemsList = new DetailItemsList({ itemType: "result", header: "Virksomhedens produktionsenheder", image: pResults[0].image })
        for (let pResult of pResults)
          detailItemsList.append({
            type: "result",
            result: pResult,
            name: "cvr_info_produktionsenheder"
          })
        return detailItemsList.asItem()
      }

    }

  }
}