/**
 * @module
 */

import yaml from 'js-yaml'

import {fetch2, fetchWithTimeout } from './utils.js'
import Controller from './Controller.js'
import classRegistry from './AllTypes.js'

/**
 *
 * builds a from a searcher/detailhandler specification
 * @example
 * var selectHandler = function (result) {
 *  logObject(result);
 *  log('<b>Valgt resultat:</b> ' + result.title);
 *};
 *
 *function doDemo() {
 *
 *  var _view = null;
 *  var _controller = null;
 *
 *  var buildControllerOptions = {
 *    controller: {blankBehavior: "search"},
 *    searchers: [
 *      {
 *        type: "Septima.Search.DawaSearcher",
 *        options: {
 *          kommunekode: 175,
 *          allowDetails: true
 *        },
 *        detailhandlers:[
 *          {type: "DemoDetailHandler",
 *            options: {title: "Handler1"}},
 *          "DemoDetailHandler2"
 *        ]
 *      },
 *      {
 *        type: "Septima.Search.GeoSearch",
 *        options: {
 *          targets: ['matrikelnumre', 'sogne'],
 *          authParams: {
 *            login: 'septima',
 *            password: 'fgd4Septima'
 *          },
 *          area: "muncode0157",
 *          "returnCentroid": true
 *        },
 *        detailhandlers:["DemoDetailHandler2"]
 *      },
 *      {
 *        type: "Septima.Search.PlanSearcher",
 *        options: {
 *          searchindexToken: 'septimaSEARCHDEMO-A7OLGHG2J4',
 *          filter: {"komnr": "269"}
 *        }
 *      },
 *      {
 *        type: "Septima.Search.CVR_enhedSearcher",
 *        options: {
 *          searchindexToken: 'septimaSEARCHDEMO-A7OLGHG2J4',
 *          filter: {"beliggenhedsadresse_kommune_kode": "157"}
 *        }
 *      },
 *      {
 *        "type": "Septima.Search.S4IndexSearcher",
 *        "options": {
 *          "host": "http://spatialsuite3141.kpc.asus:8080/",
 *          "kapow": "http://v3100.sps-demo.septima.dk/",
 *          "datasources": "*",
 *          "allowDetails": true,
 *          "blankBehavior": "search"
 *        }
 *      },
 *      {
 *        type: "Septima.Search.DawaStednavnSearcher",
 *        options: {
 *          types: [{
 *            name: 'Bebyggelse',
 *            hovedtype: 'Bebyggelse|Bygning'
 *          }, {
 *            name: 'Motor og motocrossbane',
 *            undertype: 'motorbane|motocrossbane',
 *            iconuri: 'data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMzc5LjgyODEyNSAwaC0yMTcuNjU2MjVjLTcyLjg3NSAwLTEzMi4xNzE4NzUgNTkuMjk2ODc1LTEzMi4xNzE4NzUgMTMyLjE3MTg3NXY3OC44MjgxMjVoLTMwdjMwaDEyMHYtMzBoLTMwdi03OC44MjgxMjVjMC0zOS44MDA3ODEgMzIuMzcxMDk0LTcyLjE3MTg3NSA3Mi4xNzE4NzUtNzIuMTcxODc1aDIxNy42NTYyNWMzOS44MDA3ODEgMCA3Mi4xNzE4NzUgMzIuMzcxMDk0IDcyLjE3MTg3NSA3Mi4xNzE4NzV2NjFjMCAzMS4xMTMyODEtMTkuODM1OTM4IDU4LjYyNS00OS4zNTE1NjIgNjguNDY4NzVsLTYwLjc4OTA2MyAyMC4yNTc4MTNjLTQxLjc3NzM0NCAxMy45NDUzMTItNjkuODU5Mzc1IDUzLjg5NDUzMS02OS44NTkzNzUgOTcuOTI5Njg3IDAgMzkuODAwNzgxLTMzLjM3MTA5NCA3Mi4xNzE4NzUtNzMuMTcxODc1IDcyLjE3MTg3NWgtMzYuNjU2MjVjLTM5LjgwMDc4MSAwLTcyLjE3MTg3NS0zMi4zNzEwOTQtNzIuMTcxODc1LTcyLjE3MTg3NXYtNzguODI4MTI1aDMwdi0zMGgtMTIwdjMwaDMwdjc4LjgyODEyNWMwIDcyLjg3NSA1OS4yOTY4NzUgMTMyLjE3MTg3NSAxMzIuMTcxODc1IDEzMi4xNzE4NzVoMzYuNjU2MjVjNzIuODc1IDAgMTMzLjE3MTg3NS01OS4yOTY4NzUgMTMzLjE3MTg3NS0xMzIuMTcxODc1IDAtMTguMTc5Njg3IDExLjU4NTkzOC0zNS4yNSAyOC44NDM3NS00MS4wMDc4MTNsNjAuNzg5MDYyLTIwLjI2OTUzMWM1NC4wNTQ2ODgtMTguMDE5NTMxIDkwLjM2NzE4OC02OC40MTAxNTYgOTAuMzY3MTg4LTEyNS4zNzg5MDZ2LTYxYzAtNzIuODc1LTU5LjI5Njg3NS0xMzIuMTcxODc1LTEzMi4xNzE4NzUtMTMyLjE3MTg3NXptMCAwIi8+PC9zdmc+'
 *          }]
 *        }
 *      },
 *      {
 *        type: "Septima.Search.DataSearcher",
 *        options: {
 *          searchableData: {type: "Septima.Search.SearchableData",
 *            options:{
 *              singular: "Person",
 *              plural: "Persons",
 *              searchProperties: ["name", "hobby"],
 *              displaynameProperty: "name",
 *              descriptionProperty: "hobby",
 *              idProperty: "number",
 *              data: [
 *                {number: 1, name: "Adam", age: 15, hobby: "Chess"},
 *                {number: 3, name: "Christine", age: 17, hobby: "Math"},
 *                {number: 2, name: "Bob", age: 16, hobby: "Soccer"},
 *                {number: 4, name: "Jenny", age: 18, hobby: "Geography"},
 *              ]
 *            }},
 *          iconURI: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE4LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDM1MCAzNTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM1MCAzNTA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGQ9Ik0xNzUsMTcxLjE3M2MzOC45MTQsMCw3MC40NjMtMzguMzE4LDcwLjQ2My04NS41ODZDMjQ1LjQ2MywzOC4zMTgsMjM1LjEwNSwwLDE3NSwwcy03MC40NjUsMzguMzE4LTcwLjQ2NSw4NS41ODcNCgkJQzEwNC41MzUsMTMyLjg1NSwxMzYuMDg0LDE3MS4xNzMsMTc1LDE3MS4xNzN6Ii8+DQoJPHBhdGggZD0iTTQxLjkwOSwzMDEuODUzQzQxLjg5NywyOTguOTcxLDQxLjg4NSwzMDEuMDQxLDQxLjkwOSwzMDEuODUzTDQxLjkwOSwzMDEuODUzeiIvPg0KCTxwYXRoIGQ9Ik0zMDguMDg1LDMwNC4xMDRDMzA4LjEyMywzMDMuMzE1LDMwOC4wOTgsMjk4LjYzLDMwOC4wODUsMzA0LjEwNEwzMDguMDg1LDMwNC4xMDR6Ii8+DQoJPHBhdGggZD0iTTMwNy45MzUsMjk4LjM5N2MtMS4zMDUtODIuMzQyLTEyLjA1OS0xMDUuODA1LTk0LjM1Mi0xMjAuNjU3YzAsMC0xMS41ODQsMTQuNzYxLTM4LjU4NCwxNC43NjENCgkJcy0zOC41ODYtMTQuNzYxLTM4LjU4Ni0xNC43NjFjLTgxLjM5NSwxNC42OS05Mi44MDMsMzcuODA1LTk0LjMwMywxMTcuOTgyYy0wLjEyMyw2LjU0Ny0wLjE4LDYuODkxLTAuMjAyLDYuMTMxDQoJCWMwLjAwNSwxLjQyNCwwLjAxMSw0LjA1OCwwLjAxMSw4LjY1MWMwLDAsMTkuNTkyLDM5LjQ5NiwxMzMuMDgsMzkuNDk2YzExMy40ODYsMCwxMzMuMDgtMzkuNDk2LDEzMy4wOC0zOS40OTYNCgkJYzAtMi45NTEsMC4wMDItNS4wMDMsMC4wMDUtNi4zOTlDMzA4LjA2MiwzMDQuNTc1LDMwOC4wMTgsMzAzLjY2NCwzMDcuOTM1LDI5OC4zOTd6Ii8+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8L3N2Zz4NCg=='
 *        }
 *      }
 *
 *    ],
 *    detailhandlers:[{ref: "DemoDetailHandler2",
 *      type: "DemoDetailHandler",
 *      options: {title: "Handler2"}}
 *    ]
 *  };
 *
 *  new Septima.Search.ControllerBuilder().setOptions(buildControllerOptions).build().then(function (controller) {
 *    controller.addOnSelectHandler(selectHandler);
 *    _view = new Septima.Search.DefaultView({
 *      input: jQuery("#inputcontainer"),
 *      controller: _controller,
 *      mouseover: false
 *    });
 *  });
 *}
 * @api
 */

export default class ControllerBuilder {
  /**
   * @param {Object} [options]
   * @param {module:js/ClassRegistry} [options.registry]
   * @param {Object=} options.logger
   * @param {String=} options.logLevel
   */
  constructor(options= {}) {
    this.classRegistry = classRegistry
    if (options.registry)
      this.classRegistry = options.registry
    
    this.logger = options.logger

    this.classRegistry.logger = this.logger

  }

  /**
     * Set options
     * @param {Object|string} options A searcher/detailhandler specification. May be js object or YAML string
     * @returns {ControllerBuilder} builder pattern
   * @api
     */
  setOptions(options) {
    this.gotOptions = new Promise((resolve, reject) => {
      if (typeof options === 'string')
        try {
          resolve(yaml.load(options))
        } catch (error) {
          const message = 'Cannot parse options'
          if (this.logger)
            this.logger.debug({ error }, message)
          reject(message)
        }
      else
        resolve(options)
    })
    return this
  }

  /**
   * Set options URL. Interpreted ad json if url ends inf ".json" else YAML
   * @param {Object|string} options A searcher/detailhandler specification. May be js object or YAML string
   * @returns {ControllerBuilder} builder pattern
   * @api
   */
  setUrl(optionsUrl) {
    this.gotOptions = new Promise(function(resolve, reject) {
      if (optionsUrl.indexOf("json") > 0) 
        fetch2(optionsUrl, {}).then(
          (options)=>{
            resolve (options)
          },
          (error)=>{
            reject (error)
          }
        )
      else 
        fetchWithTimeout(optionsUrl).then(
          async(response)=>{
            let yml = await response.text()
            resolve (yaml.load(yml))
          },
          (error)=>{
            reject (error)
          }
        )
        
    })

    return this
  }

  /**
   * @async
   * @returns {module:js/Controller} Controller
   * @throws {Error}
   * @api
   */
  async build() {
    const start = Date.now()
    let controller
    let options = await this.gotOptions
    let resolvedOptions = this.classRegistry.resolve(options)
    if (typeof resolvedOptions.controller === 'undefined') {
      throw new Error("Options don't specify a controller instance")
    } else if (resolvedOptions.controller instanceof Controller) {
      controller = resolvedOptions.controller
    } else {
      controller = new Controller(resolvedOptions.searchers, resolvedOptions.controller)
    }
    const duration = Date.now() - start
    let logObject = {}
    logObject.module = 'ControllerBuilder'
    logObject.method = 'build()'
    logObject.duration = duration
    if (this.logger)
      this.logger.debug(logObject, 'built')
    return controller
  }
}

