import {fetch2} from "../utils.js"
import {EnhedsAdresseAPI} from "./EnhedsAdresse.dawa.api.js"

export class AdgangsAdresseAPI {
  /*
   * @param {Object} options AdresseAPI expects these options:
   * @param {string} [options.kommunekode='*']  "*" Search all municipalities (Default)</br>Search specific municipalities eg. "101" or "101|256"
   * @param {string} options.serviceUrl
  */

  constructor(options) {
    this.kommunekode  = "*"
    if (typeof options.kommunekode !== 'undefined')
      this.kommunekode = options.kommunekode.toString()
    this.serviceUrl = options.serviceUrl
  }

  /*
   * @param {Object} query 
   * @param {string} query.query
   * @param {string} oquery.limit
  */
  query(query) {
    let callParameters = {
      q: query.query === "" ? "a" : query.query,
      type: "adgangsadresse",
      side: 1,
      per_side: query.limit + 1,
      noformat: 1,
      srid: 25832
    }
    if (this.kommunekode !== "*")
      callParameters.kommunekode = this.kommunekode
    
    return new ApiResult(this.serviceUrl, callParameters, this.responseSorter)
  }

  async get(id) {
    let data = await fetch2(this.serviceUrl + "/adgangsadresser/" + id, {data: {srid: 25832, format: "geojson", struktur: "nestet"}})
    return data
  }

  async gets(ids) {
    let size = 100
    let features = []
    let getFeaturesResultPromises = []
    for (let i=0; i<ids.length; i+=size) {
      let idsToGet = ids.slice(i, i+size)
      getFeaturesResultPromises.push( fetch2(this.serviceUrl + "/adgangsadresser/", {data: {id: idsToGet.join("|"), srid: 25832, format: "geojson", struktur: "nestet"}}))
    }

    let getFeaturesResults = await Promise.all(getFeaturesResultPromises)
    for (let j=0;j<getFeaturesResults.length;j++) {
      let getFeaturesResult = getFeaturesResults[j]
      let newFeatures = getFeaturesResult.features
      features = [...features, ...newFeatures]
    }
    //for (let getFeaturesResult of getFeaturesResults)
    let returnArray = features

    return {features: returnArray}
  }

  async sq(wkt) {
    let params = {
      servicename: 'WFS',
      typename: "aws4_wfs_secure:adgangsadresser",
      request: 'GetFeature',
      version: '1.0.0',
      outputformat: 'json',
      CQL_FILTER: 'intersects(geom,' + wkt + ')'
    }
    let result = await fetch2('https://kort.aws.dk/geoserver/wfs', {data: params, method: 'post'})
    let features = result.features
    let ids = []
    for (let feature of features)
      ids.push(feature.id.substring(16))
    let apiFeaturesResult = await this.gets(ids)
    return apiFeaturesResult.features
  }
  
  responseSorter(r1, r2) {
    try {
      const h1 = parseInt(r1.adgangsadresse.husnr)
      const h2 = parseInt(r2.adgangsadresse.husnr)
      const p1 = parseInt(r1.adgangsadresse.postnr)
      const p2 = parseInt(r2.adgangsadresse.postnr)
      if (h1 === h2)
        return (p1 - p2)
      else
        return (h1 - h2)

    } catch (error) {
      return 0
    }
  }

  sortAdresseResponse(response) {
    //	    {
    //	        "id": "0a3f507c-8d5d-32b8-e044-0003ba298018",
    //	        "href": "https://dawa.aws.dk/adgangsadresser/0a3f507c-8d5d-32b8-e044-0003ba298018",
    //	        "vejnavn": "Sortsøvej",
    //	        "husnr": "6",
    //	        "supplerendebynavn": null,
    //	        "postnr": "2730",
    //	        "postnrnavn": "Herlev",
    //	        "stormodtagerpostnr": null,
    //	        "stormodtagerpostnrnavn": null
    //	    }
    return response.sort(this.responseSorter)
  }

}

class ApiResult {
  constructor(serviceUrl, callParameters, responseSorter) {
    this.serviceUrl = serviceUrl
    this.callParameters = callParameters
    this.responseSorter = responseSorter
    this.response = null
  }
  
  async responseReady() {
    if (!this.response)
      this.response = await this.fetchAdgangsAdresser(this.callParameters)
  }

  async getCount() {
    await this.responseReady()
    return this.response.length
  }


  async getAdgangsAdresseHits(limit) {
    await this.responseReady()
    let hits = []
    for (let i = 0; (i < this.response.length) && (hits.length < limit); i++) {
      const adr = this.response[i]
      let geometry = {
        "type": "Point",
        "coordinates": [
          adr.adgangsadresse.x,
          adr.adgangsadresse.y
        ]
      }
      geometry.crs = {
        "type": "name",
        "properties": {
          "name": "epsg:25832"
        }
      }
      hits.push({type: "adgangsadresseinfo", tekst: adr.tekst, id: adr.adgangsadresse.id, geometry: geometry, kommunekode: adr.adgangsadresse.kommunekode, vejkode: adr.adgangsadresse.vejkode})
    }
    return hits
  }
  
  async getAdgangsAdresseHitsMedEnhedsInfo(limit) {
    await this.responseReady()
    let adgangsAdresser = this.response.map(adr=>adr.adgangsadresse.id)
    let enhedsAdresseAPI = new EnhedsAdresseAPI({serviceUrl: this.serviceUrl })
    let {adresser, skyggeAdresser} = await enhedsAdresseAPI.getEnhedsAdresserForAdresser(adgangsAdresser)
    let hits = []
    for (let i = 0; (i < this.response.length) && (hits.length < limit); i++) {
      const adr = this.response[i]
      let geometry = {
        "type": "Point",
        "coordinates": [
          adr.adgangsadresse.x,
          adr.adgangsadresse.y
        ]
      }
      geometry.crs = {
        "type": "name",
        "properties": {
          "name": "epsg:25832"
        }
      }
      let adrId = adr.adgangsadresse.id
      let hit = {
        type: "adgangsadresseinfo",
        tekst: adr.tekst,
        id: adrId,
        geometry: geometry,
        enhedsAdresser: [],
        skyggeAdresse: null,
        kommunekode: adr.adgangsadresse.kommunekode,
        vejkode: adr.adgangsadresse.vejkode
      }
      if (adresser[adrId])
        hit.enhedsAdresser = adresser[adrId]
      if (skyggeAdresser[adrId])
        hit.skyggeAdresse = skyggeAdresser[adrId]
      hits.push( hit )
    }
    return hits
  }
  
  async fetchAdgangsAdresser(callParameters) {
    let query = callParameters.q.trim().toLowerCase()
    let responses
    let directResponsesPromise = fetch2(this.serviceUrl + "/adgangsadresser/autocomplete", {data: callParameters})
    callParameters.q = callParameters.q + " "
    let blankAppendixResponses = await fetch2(this.serviceUrl + "/adgangsadresser/autocomplete", {data: callParameters})
    let directResponses = await directResponsesPromise
    if (blankAppendixResponses.length === directResponses.length) {
      responses = directResponses
    }else{
      for (let i = directResponses.length -1; i >=0; i--) {
        let directAdr = directResponses[i]
        for (let j=0;j < blankAppendixResponses.length; j++)
          if (blankAppendixResponses[j].adgangsadresse.id === directAdr.adgangsadresse.id)
            directResponses.splice(i, 1)
      }
      responses = blankAppendixResponses.concat(directResponses)
    }
    for (let response of responses) {
      if (response.tekst.trim().toLowerCase() == query) {
        responses = [response]
        break
      }
    }
    responses = responses.sort(this.responseSorter)
    return responses
  }
}
