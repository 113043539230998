import Searcher from './Searcher.js'
import DetailsHandlerDef from "../details/DetailsHandlerDef.js"
import {getWKTParser} from "../util/getWKTParser.js"
import * as reproject from "../util/reproject.js"

/**
 *
 * @class Searches Septima Search Server (S3)
 * @extends module:js/searchers/Searcher
 *
 */

export default class S3Searcher extends Searcher {
  /**
   *
   * @param {Object} options S3Searcher expects these properties:
   * @param {string} options.host The S3 host, e.g. "http://s3.demo.septima.dk"
   * @param {string} options.service The configuration e.g. "/api/v1/organisations/septima/configurations/detailitemdemo"
   *
   */
  constructor(options= {}) {
    if (typeof options.host === "undefined" || typeof options.host === "undefined")
      throw "New Septima.Search.S3Searcher(options): Options host is missing"
    super(options)
    
    this.indexedDatasources = null

    this.s3Host = options.host.replace(/\/$/, "") //remove trailing slash
    this.organisation = options.organisation
    this.configuration = options.configuration
    
    if (this.organisation && this.configuration) {
      this.service = `/api/v1/organisations/${this.organisation}/configurations/${this.configuration}`
    } else {
      if (typeof options.service === "undefined" )
        throw "New Septima.Search.S3Searcher(options): Options service or organisation/configuration is missing"
      this.service = options.service
    }
    
    this.showPdfLink = false
    if (options.showPdfLink)
      this.showPdfLink = options.showPdfLink

    this.showLinkToWeb = false
    if (options.showLinkToWeb)
      this.showLinkToWeb = options.showLinkToWeb

    this.authorizationOption = {}
    if (options.authorization) {
      //Must have this format {Bearer: {token: } }
      if (options.authorization.Bearer && options.authorization.Bearer.token)
        this.authorizationOption = {Authorization: options.authorization}
      else
        throw "New Septima.Search.S3Searcher(options): Bearer token not specified correctly ({Bearer: {token: } })"
    }
    
    this.generalFetchOptions = Object.assign(
      {},
      {timeout: 20000},
      this.authorizationOption
    )

    this.endPoint = this.s3Host + this.service

    this.sources = null
    this.serverInfo = {
      crsAware: false,
      _updated: false,
      supportsPdf: false
    }
    this.infoReady = this.getS3Info()
  }

  async getS3Info() {
    if (this.sources === null) {
      this.sources = []
      this.sources = await this.fetch(this.endPoint + '/sources', Object.assign({}, this.generalFetchOptions))
      //The s3 response is controller.getSources() serialized
      for (let source of this.sources) {
        let sourceName = source.source
        for (let type of source.types) 
          this.registerType(sourceName, type)
      }
    }
    if (!this.serverInfo._updated) {
      this.serverInfo._updated = true
      try {
        let serverDescription = await this.fetch(this.s3Host + '/api/v1/description', Object.assign({}, this.generalFetchOptions))
        if (serverDescription.metadata && serverDescription.metadata.version) {
          let majorVer = parseInt(serverDescription.metadata.version.split(".")[0])
          let minorVer = parseInt(serverDescription.metadata.version.split(".")[1])
          if (majorVer >= 1) {
            this.serverInfo.crsAware = true
            if (minorVer >= 3)
              this.serverInfo.supportsPdf = true
          }
        }
        // eslint-disable-next-line no-empty
      } catch{}
    }
  }

  ready() {
    return this.infoReady
  }

  async fetchData(query, caller) {
    try {
      await this.infoReady
      this.myFetchData(query, caller)
    } catch (error) {
      caller.fetchError()
    }
  }

  async sq(query) {
    let queryResult = this.createQueryResult()
    if (query.geometry) {
      
      let path = ''
      if (query.hasTarget)
        path = this.getPathFromTarget(query.target)
      let url = this.endPoint + path

      let wktParser = getWKTParser()
      const queryWkt = wktParser.convert(query.geometry)
      let fetchOptions = Object.assign({data: {sq: queryWkt}}, this.generalFetchOptions)

      if (this.serverInfo.crsAware) {
        let epsgCode = reproject.detectEpsgCode(query.geometry)
        if (epsgCode)
          fetchOptions.data.srs = epsgCode

        if (query.allow_touches)
          fetchOptions.data.allow_touches = true
      }
      
      let data = await this.fetch(url, fetchOptions)
      queryResult = this.createQueryResultFromData(data)
    }
    return queryResult
  }

  async myFetchData(query, caller) {
    let url
    switch (query.type) {
      case 'collapse': {
        let path = ''
        if (query.hasTarget)
          path = this.getPathFromTarget(query.target)
        url = this.endPoint + path + "?q="
        break
      }
      case 'cut':
      case 'no-cut':
      case 'list': {
        let path = ''
        if (query.hasTarget)
          path = this.getPathFromTarget(query.target)
        url = this.endPoint + path + "?q=" + query.queryString
      }
    }
    try {
      let data = await this.fetch(url, Object.assign({}, this.generalFetchOptions))
      data.query = query
      this.success(caller, data)
    } catch (error) {
      caller.fetchError()
    }
  }

  getPathFromTarget(target) {
    return "/sources/" + target.source + "/types/" + target.type
  }

  getSourcesAsNewQueries() {
    const queryResult = this.createQueryResult()
    for (let source of this.sources) {
      let sourceName = source.source
      for (let type of source.types) 
        queryResult.addNewQuery(sourceName, type, type, null, "", null, null)
      
    }
    return queryResult
  }

  success(caller, data) {
    let queryResult = this.createQueryResultFromData(data)
    caller.fetchSuccess(queryResult)
  }
  
  createQueryResultFromData(data) {
    const queryResult = this.createQueryResult()
    for (let thisItem of data)
      if (thisItem.item === "newquery") {
        let newQuery = queryResult.addNewQuery(thisItem.source, thisItem.type, thisItem.title, thisItem.description, thisItem.newquery, null, null)
        newQuery.image = thisItem.image
      } else if (thisItem.item === "shortresult") {
        let result = queryResult.addResult(thisItem.source, thisItem.type, thisItem.title, thisItem.description, null, null)
        result._s3data = thisItem
        result.image = thisItem.image
        result.id = thisItem.id
        result.isComplete = false
      } else if (thisItem.item === "deepresult") {
        let geometry = thisItem.geometry
        if (geometry && !this.serverInfo.crsAware) {
          geometry.crs  = {
            "type": "name",
            "properties": {
              "name": "epsg:25832"
            }
          }
        }
        let result = queryResult.addResult(thisItem.source, thisItem.type, thisItem.title, thisItem.description, geometry, null)
        result._s3data = thisItem
        result.image = thisItem.image
        if (thisItem.id)
          result.id = thisItem.id
      }
    return queryResult
  }

  async completeResult(result) {
    if (result.isComplete) {
      return result
    } else {
      result.isComplete = true
      let s3Result = await this.fetch( this.s3Host + result._s3data.href, Object.assign({}, this.generalFetchOptions))
      result.data = s3Result.data
      result.geometry = s3Result.geometry
      if (s3Result.geometry && !this.serverInfo.crsAware) {
        result.geometry.crs  = {
          "type": "name",
          "properties": {
            "name": "epsg:25832"
          }
        }
      }
      return result
    }
  }

  hasdetailHandlerDefs(result) {
    if (result._s3data.details && result._s3data.details.length > 0) 
      return true
    else 
      return false
  }

  getCustomButtonsForResult(result) {
    let buttons = []
    
    if (this.showLinkToWeb && this.organisation && this.configuration) {
      buttons.push({
        buttonText: "Vis " + result.title + " i OneDoor Web",
        buttonImage: Septima.Search.icons.onedoorlink,
        "callBack": (result) => {
          let url = `${this.s3Host}/#/${this.organisation}/${this.configuration}/${result.source}/${result.typeId}/${result.id}`
          window.open(url, "_blank")
        }
      })
    }
    
    if (this.serverInfo.supportsPdf && this.showPdfLink && this.hasdetailHandlerDefs(result)) {
      buttons.push({
        "buttonText": "Download PDF-rapport for " + result.title,
        "buttonImage": Septima.Search.icons.pdf,
        "callBack": (result) => {
          this.report(result.id, result.typeId, result.source, "pdf").then((blob)=>{
            const newBlob = new Blob([blob], { type: 'application/pdf' })

            // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob)
            } else {
              // For other browsers: create a link pointing to the ObjectURL containing the blob.
              const objUrl = window.URL.createObjectURL(newBlob)

              let link = document.createElement('a')
              link.href = objUrl
              link.target = "_blank"
              // eslint-disable-next-line no-useless-escape
              link.download = result.title.replace(/[\/|\\:*?"<>,.]/g, "").replace(/[ ]/g, "_")
              link.click()

              // For Firefox it is necessary to delay revoking the ObjectURL.
              setTimeout(() => {
                window.URL.revokeObjectURL(objUrl)
              }, 250)
            }
          })
          return null
        }
      })
    }
    return buttons
  }

  async report(id, type, source, format = "json") {
    let url = this.endPoint + "/sources/" + source + "/types/" + type + "/" + id + "/report"
    let options = {}
    if (format == "pdf") {
      options = { expects: "blob", headers: { 'Content-Type': 'application/pdf' }}
      return this.fetch( url , Object.assign(options, this.generalFetchOptions))
    } else {
      return this.fetch( url , Object.assign(options, this.generalFetchOptions))
    }
  }
  
  async get(id, type, source) {
    const queryResult = this.createQueryResult()
    let url = this.endPoint + "/sources/" + source + "/types/" + type + "/" + id
    let data = await this.fetch( url , Object.assign({}, this.generalFetchOptions))
    let s3Result = data
    if (s3Result.item === "result") {
      let geometry = s3Result.geometry
      if (geometry && !this.serverInfo.crsAware) {
        geometry.crs  = {
          "type": "name",
          "properties": {
            "name": "epsg:25832"
          }
        }
      }
      let result = queryResult.addResult(s3Result.source, s3Result.type, s3Result.title, s3Result.description, geometry, s3Result.data)
      result._s3data = s3Result
      result.image = s3Result.image
      if (s3Result.id) 
        result.id = s3Result.id
      return result
    }
  }

  convertResultdetailItemToResult(detailItem) {
    let queryResult = this.createQueryResult()
    let s3Result = detailItem.result
    let result = queryResult.addResult(s3Result.source, s3Result.type, s3Result.title, s3Result.description, null, s3Result.data)
    if (s3Result.geometry && s3Result.geometry !== null && s3Result.geometry !== "") {
      result.geometry = s3Result.geometry
      if (!this.serverInfo.crsAware) {
        result.geometry.crs  = {
          "type": "name",
          "properties": {
            "name": "epsg:25832"
          }
        }
      }
    }

    result._s3data = s3Result
    result.isComplete = false
    result.image = s3Result.image
    result.id = s3Result.id
    return result
  }
  
  getDetailHandlersForResult(result) {
    const details = result._s3data.details
    let detailHandlerDefs = []
    if (typeof details !== 'undefined') 
      for (let thisDetail of details) 
        if (thisDetail.href) {
          let detailHandlerDef = new DetailsHandlerDef(
            {"buttonText": thisDetail.title,
              "buttonImage": thisDetail.image,
              "handler": async() => {
                let returnItems = []
                let response =  await this.fetch(this.s3Host + thisDetail.href, Object.assign({}, this.generalFetchOptions))
                if (response.message === 'S3Error')
                  throw new Error('S3Error: ' + response.inner)
                for (let detailItem of response) {
                  if (detailItem.type === 'result') 
                    detailItem.result = this.convertResultdetailItemToResult(detailItem)
                  else if (detailItem.type === 'list')
                    this.processList(detailItem)
                   
                  returnItems.push(detailItem)
                }
                return returnItems  
              },
              "renderHints": thisDetail.renderHints,
              "more": thisDetail.more,
              "id": thisDetail.id}
          )
          detailHandlerDefs.push(detailHandlerDef)
        }
    return detailHandlerDefs
  }
  
  processList(listItem) {
    for (let item of listItem.items) {
      if (item.type === 'result')
        item.result = this.convertResultdetailItemToResult(item)
      else if (item.type === 'list')
        this.processList(item)

      if (item.infoItems)
        for (let infoItem of item.infoItems)
          if (infoItem.type === 'result')
            infoItem.result = this.convertResultdetailItemToResult(infoItem)
    }
  }

}

