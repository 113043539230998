import coreTypes from './CoreTypes.js'
import dkTypes from './DkTypes.js'
import redataTypes from './searchers/redata/RedataTypes.js'
import geoserverTypes from './searchers/geoserver/GeoserverTypes.js'
import dataApiTypes from './searchers/data-api/DataApiTypes.js'

let allTypes = coreTypes.spawn()
allTypes.inject(dkTypes)
allTypes.inject(redataTypes)
allTypes.inject(geoserverTypes)
allTypes.inject(dataApiTypes)
export default allTypes
