/**
 * @module
 */
import CvrSearcher from "./redata/CvrSearcher.js"
import Fetcher from "./redata/Fetcher.js"

/**
 *
 * DEPRECATED - Use Septima.Search.DataApi.CvrSearcher
 * Supports GET<br/>
 * SQ support<br/>
 * @extends module:js/searchers/Searcher
 * @example <caption>YAML Declaration:</caption>
 _type: Septima.Search.CVR_enhedSearcher
 _options:
   searchindexToken: septimaSEARCHDEMO-A7OLGHG2J4
   kommunekode: 101

 * @example <caption> JS options: <a href='../examples/simple.dk/'>Example</a></caption>

 cvrSearcherOptions = {
    searchindexToken: 'TOKEN'
  };
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * controller.addSearcher(new Septima.Search.CVR_enhedSearcher(cvrSearcherOptions))
 *
 * @example <caption>ES6:</caption>
 * import CVR_enhedSearcher from './searchers/CVR_enhedSearcher.js'
 * controller.addSearcher(new CVR_enhedSearcher(cvrSearcherOptions))
 *
 * @api
 */

export default class CVR_enhedSearcher extends CvrSearcher {
  /**
   *
   * @param {Object} options CVR_enhedSearcher expects these properties:
   * @param {string} [options.kommunekode='*']  "*" Search all municipalities (Default) - Search specific municipalities eg. "101" or "101|256"
   * @param {string} [options.singular=Produktionsenhed]
   * @param {string} [options.plural=Produktionsenheder]
   * @api
   */
  constructor(options= {}) {
    // eslint-disable-next-line no-console
    if (console && console.warn) {
      try{
        // eslint-disable-next-line no-console
        console.warn("CVR_enhedSearcher is deprecated - Use Septima.Search.DataApi.CvrSearcher")
        // eslint-disable-next-line no-empty
      } catch(e) {}
    }

    let fetcher = new Fetcher({
      token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoicHJvZF9yZWRhdGFfcG9zdGdyZXN0X2xhZXNlciIsImV4cCI6MjI3ODEwMjc3MH0.PQTLb8k58I3zdKWfd-CssFxz_16nIBmDNvdLFwOrICk"
    })
    
    let cvrSearcherOptions = Object.assign({}, options, {
      fetcher: fetcher,
      goal: "produktionsenhed"
    })
    
    if (options.kommunekode && options.kommunekode != "*") {
      let cvrSearcherKommunekode = ( "" + options.kommunekode).split("|").join(" ")
      cvrSearcherOptions.kommunekode = cvrSearcherKommunekode
    }
    
    super(cvrSearcherOptions)

  }

  async getVirksomhed(cvrNummer) {
    let virksomhedsResult = await super.get(cvrNummer, "virksomhed")
    if (virksomhedsResult && virksomhedsResult.data)
      return virksomhedsResult.data
  }
  
  async getProduktionsEnheder(cvrNummer) {
    return super.getProduktionsEnhederForCvrNummer(cvrNummer)
  }
  
  async getVirksomhederForAdresse(vejkode, husnummer, komkode) {    
    let url = `https://api.dataforsyningen.dk/adgangsadresser?kommunekode=${komkode}&vejkode=${vejkode}&husnr=${husnummer}&struktur=mini`
    let husnummerInfo = await this.fetch(url)
    if (husnummerInfo && husnummerInfo.length == 1) {
      let hId = husnummerInfo[0].id
      return super.getProduktionsenhederForHusnummer(hId)
    } else {
      return this.createQueryResult()
    }
  }

  
}
