/**
 * @module
 */

import Searcher from './Searcher.js'
import ResultType from "../ResultType.js"

/**
 *  
 * Searches a {module:js/searchers/local/SearchableData}
 * @extends module:js/searchers/Searcher
 * @example <caption>YAML Declaration</caption>
persons:
  personSearcher:
    _type: Septima.Search.DataSearcher
    _options:
      searchableData:
        _ref: "$.persons.searchablepersons"
      iconURI: data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE4LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDM1MCAzNTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM1MCAzNTA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGQ9Ik0xNzUsMTcxLjE3M2MzOC45MTQsMCw3MC40NjMtMzguMzE4LDcwLjQ2My04NS41ODZDMjQ1LjQ2MywzOC4zMTgsMjM1LjEwNSwwLDE3NSwwcy03MC40NjUsMzguMzE4LTcwLjQ2NSw4NS41ODcNCgkJQzEwNC41MzUsMTMyLjg1NSwxMzYuMDg0LDE3MS4xNzMsMTc1LDE3MS4xNzN6Ii8+DQoJPHBhdGggZD0iTTQxLjkwOSwzMDEuODUzQzQxLjg5NywyOTguOTcxLDQxLjg4NSwzMDEuMDQxLDQxLjkwOSwzMDEuODUzTDQxLjkwOSwzMDEuODUzeiIvPg0KCTxwYXRoIGQ9Ik0zMDguMDg1LDMwNC4xMDRDMzA4LjEyMywzMDMuMzE1LDMwOC4wOTgsMjk4LjYzLDMwOC4wODUsMzA0LjEwNEwzMDguMDg1LDMwNC4xMDR6Ii8+DQoJPHBhdGggZD0iTTMwNy45MzUsMjk4LjM5N2MtMS4zMDUtODIuMzQyLTEyLjA1OS0xMDUuODA1LTk0LjM1Mi0xMjAuNjU3YzAsMC0xMS41ODQsMTQuNzYxLTM4LjU4NCwxNC43NjENCgkJcy0zOC41ODYtMTQuNzYxLTM4LjU4Ni0xNC43NjFjLTgxLjM5NSwxNC42OS05Mi44MDMsMzcuODA1LTk0LjMwMywxMTcuOTgyYy0wLjEyMyw2LjU0Ny0wLjE4LDYuODkxLTAuMjAyLDYuMTMxDQoJCWMwLjAwNSwxLjQyNCwwLjAxMSw0LjA1OCwwLjAxMSw4LjY1MWMwLDAsMTkuNTkyLDM5LjQ5NiwxMzMuMDgsMzkuNDk2YzExMy40ODYsMCwxMzMuMDgtMzkuNDk2LDEzMy4wOC0zOS40OTYNCgkJYzAtMi45NTEsMC4wMDItNS4wMDMsMC4wMDUtNi4zOTlDMzA4LjA2MiwzMDQuNTc1LDMwOC4wMTgsMzAzLjY2NCwzMDcuOTM1LDI5OC4zOTd6Ii8+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8L3N2Zz4NCg==
  searchablepersons:
    _type: Septima.Search.SearchableData
    _options:
      singular: Person
      plural: Persons
      searchProperties:
        - name
        - hobby
      displaynameProperty: name
      descriptionProperty: hobby
      idProperty: number
      data:
        _ref: "$.persons.persons"
  persons:
    - number: 1
      name: Adam
      age: 15
      hobby: Chess
    - number: 3
      name: Christine
      age: 17
      hobby: Math
    - number: 2
      name: Bob
      age: 16
      hobby: Soccer
    - number: 4
      name: Jenny
      age: 18
      hobby: Geography

 * @example <caption> JS options:</caption>
  persons = [
    {number: 1, name: "Adam", age: 15, hobby: "Chess"},
    {number: 3, name: "Christine", age: 17, hobby: "Math"},
    {number: 2, name: "Bob", age: 16, hobby: "Soccer"},
    {number: 4, name: "Jenny", age: 18, hobby: "Geography"},
  ]

 searchAbleDataOptions = {
    singular: "Person",
    plural: "Personer",
    searchProperties: ["name", "hobby"],
    displaynameProperty: "name",
    descriptionProperty: "age",
    idProperty: "number",
    data: persons
  }
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * var searchAbleData = new Septima.Search.SearchableData(searchAbleDataOptions)
 * controller.addSearcher(new Septima.Search.DataSearcher(searchAbleData))
 *
 * @example <caption>ES6:</caption>
 * import DataSearcher from './searchers/DataSearcher.js'
 * import SearchableData from './searchers/local/SearchableData.js'
 * let searchAbleData = new SearchableData(searchAbleDataOptions)
 * controller.addSearcher(new DataSearcher(searchAbleData))
 *
 * @api
 */
export default class DataSearcher extends Searcher {

  /**
   *
   * @param {Object} options DataSearcher expects these properties:
   * @param options.searchableData {module:js/searchers/local/SearchableData}
   */
  constructor(options) {
    super(options)
    this.searchableData = options.searchableData
    
    this.source = this.searchableData.plural.toLowerCase()
    if (options.source)
      this.source =  options.source
    
    this.typeId = this.searchableData.singular.toLowerCase()
    if (options.typeId) 
      this.typeId = options.typeId
    let resultType = new ResultType({
      id: this.typeId,
      singular: this.searchableData.singular,
      plural: this.searchableData.plural
    })
    
    this.registerType(this.source, resultType)
  }
		
  async fetchData(query, caller) {
		
    const queryResult = this.createQueryResult()
		
    const hits = await this.searchableData.query(query.queryString)
		
    const count = hits.length

    if (["list", "cut", "no-cut"].indexOf(query.type) !== -1) {
      let hitsShown = (count === 1) ? 1 : (query.type === 'no-cut' && count > query.limit) ? 0 : Math.min(count, query.limit)
      for (let hit of hits.slice(0, hitsShown)) {
        let displayname = hit.title
        if (!query.hasTarget && query.type !== "list")
          displayname += " (" +this.searchableData.singular + ")"

        let description = hit.description
        let geometry = null
        if (hit.geometry)
          geometry = hit.geometry

        let result = queryResult.addResult(this.source, this.typeId, displayname, description, geometry, hit.object)
        if (hit.id)
          result.id = hit.id
      }
      if ( count > hitsShown && ["no-cut", "cut"].indexOf(query.type) !== -1 ) 
        queryResult.addNewQuery(this.source, this.typeId, this.searchableData.plural + " (" + count + ")", null, query.queryString, null, null)

    } else {
      if (count > 0)
        queryResult.addNewQuery(this.source, this.typeId, this.searchableData.plural + " (" + count + ")", null, query.queryString, null, null)
    }

    setTimeout(()=>{
      caller.fetchSuccess(queryResult)
    }, 100)
  }
  
  async get(id) {
    if (this.searchableData.isGettable()) {
      let hit = await this.searchableData.get(id)
      if (hit!== null) {
        const queryResult = this.createQueryResult()
        let displayname = hit.title
        let description = hit.description
        let geometry = null
        if (hit.geometry)
          geometry = hit.geometry

        let result = queryResult.addResult(this.source, this.typeId, displayname, description, geometry, hit.object)
        result.id = hit.id
        return result
      }else{
        throw new Error('Whoops! get(id) failed in ' + this.typeId + ' searcher')
      }
    } else 
      throw new Error('Whoops! get(id) is not implemented in ' + this.typeId + ' searcher')
  }

}
